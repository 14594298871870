import React, { useEffect } from 'react';
import { Card, Form, Input, Button, Select, message } from 'antd';
import { createUser, updateUser } from '../../context/dbTransactions/users';

const { Option } = Select;

const roles =  ['student', 'teacher', 'trainer', 'principal'];

const ManageAppUsersForm = ({ user, schools, onClose, fetchUsers }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user, form]);

  const onFinish = async (values) => {
    try {
      if (user) {
        await updateUser(user._id, values);
        message.success('User updated successfully.');
      } else {
        await createUser(values);
        message.success('User created successfully.');
      }
      fetchUsers();
      onClose();
    } catch (error) {
      message.error('Failed to save user.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card title={user ? "Edit User" : "Add User"} style={{ maxWidth: 600, margin: 'auto' }}>
      <Form
        form={form}
        name="manage_user"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input the password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: 'Please select the role!' }]}
        >
          <Select>
            {roles.map((role) => (
              <Option key={role} value={role}>
                {role.toLocaleUpperCase()}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="School Association"
          name="schoolAssociationRef"
          rules={[{ required: true, message: 'Please select the school!' }]}
        >
          <Select>
            {schools.map((school) => (
              <Option key={school._id} value={school._id}>
                {school.school_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ManageAppUsersForm;
