import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin, message } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import configs from '../lib/config';
import { getAnalyticsData } from '../context/dbTransactions/analytics';

const Dashboard = () => {
  const [data, setData] = useState({ schoolMonthlyData: [], projectMonthlyData: [] });
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState({
    totalSchools: 0,
    verifiedSchools: 0,
    pendingVerifications: 0,
    totalProjects: 0,
    submissions: 0,
    totalAppUsers: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const analyticsData = await getAnalyticsData();
        setAnalytics(analyticsData);
        setData(analyticsData.monthlyData);
      } catch (error) {
        message.error('Failed to fetch analytics data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Total Schools" bordered={false} hoverable>
            <h2>{analytics.totalSchools}</h2>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Verified Schools" bordered={false} hoverable>
            <h2>{analytics.verifiedSchools}</h2>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Pending Verifications" bordered={false} hoverable>
            <h2>{analytics.pendingVerifications}</h2>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col span={8}>
          <Card title="Total Projects" bordered={false} hoverable>
            <h2>{analytics.totalProjects}</h2>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Submissions" bordered={false} hoverable>
            <h2>{analytics.submissions}</h2>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total App Users" bordered={false} hoverable>
            <h2>{analytics.totalAppUsers}</h2>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={12}>
          <Card title="Schools Overview" bordered={false}>
            <LineChart width={500} height={300} data={data.schoolMonthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Total" stroke={configs.theme} activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="Verified" stroke="#82ca9d" />
            </LineChart>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Project Submissions" bordered={true}>
            <BarChart width={500} height={300} data={data.projectMonthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Total" fill={configs.theme} />
              <Bar dataKey="Verified" fill="#82ca9d" />
            </BarChart>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
