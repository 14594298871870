// src/pages/School.js
import React, { useState, useEffect } from 'react';
import {Card, Table, Button, Drawer, message, Modal} from 'antd';
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import RegisterSchools from '../components/school/RegisterSchools';
import ManageSchool from '../components/school/ManageSchool';
import { getSchools, deleteSchool } from '../context/dbTransactions/school';

const School = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [schools, setSchools] = useState([]);
    const [editingSchool, setEditingSchool] = useState(null);
    const [managingSchool, setManagingSchool] = useState(null);

    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const data = await getSchools();
                const approvedSchools = data.filter(school => school.verification_status === 'approved');
                setSchools(approvedSchools);
            } catch (error) {
                message.error('Failed to fetch schools.');
                console.error('Error fetching schools:', error);
            }
        };

        fetchSchools();
    }, []);
    const showDrawer = (school = null) => {
        setEditingSchool(school);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setEditingSchool(null);
        setManagingSchool(null);
    };

    const handleEdit = (record) => {
        showDrawer(record);
    };

    const confirmDelete = (schoolId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this school?',
            onOk: () => handleDelete(schoolId),
        });
    };

    const handleDelete = async (schoolId) => {
        try {
            await deleteSchool(schoolId);
            setSchools(schools.filter(school => school.uid !== schoolId));
            message.success('School deleted successfully.');
        } catch (error) {
            message.error('Failed to delete school.');
            console.error('Error deleting school:', error);
        }
    };

    const handleManage = (record) => {
        setManagingSchool(record);
        setDrawerVisible(true);
    };


 const columns = [
  {
    title: 'School Name',
    dataIndex: 'school_name',
    key: 'school_name',
  },
  {
    title: 'UDIAS',
    dataIndex: 'school_udias',
    key: 'school_udias',
  },
     {
         title: 'Phase Status',
         dataIndex: 'phaseStatus',
         key: 'phaseStatus',
     },
  {
    title: 'District',
    dataIndex: 'school_district',
    key: 'school_district',
  },
  {
    title: 'State',
    dataIndex: 'school_state',
    key: 'school_state',
  },

  {
    title: 'Type',
    dataIndex: 'school_type',
    key: 'school_type',
  },

  // {
  //   title: 'Logo',
  //   dataIndex: 'school_logo',
  //   key: 'school_logo',
  //   render: (text) => <img src={text} alt="School Logo" style={{ width: 50, height: 50 }} />,
  // },
     {
         title: 'Actions',
         key: 'actions',
         render: (text, record) => (
             <span>
          <Button icon={<EditOutlined />} style={{ marginRight: 8 }} onClick={() => handleEdit(record)} />
<Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} onClick={() => confirmDelete(record._id)} />          <Button icon={<SettingOutlined />} onClick={() => handleManage(record)} />
        </span>
         ),
     },
];

    return (
        <div>
            <Card
                title="Schools"
                extra={<Button type="primary" onClick={() => showDrawer()}>Register</Button>}
            >
                <Table columns={columns} dataSource={schools} rowKey="uid" />
            </Card>
            <Drawer
                title={editingSchool ? "Edit School" : managingSchool ? "Manage School" : "Register School"}
                width={720}
                onClose={closeDrawer}
                visible={drawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                {editingSchool ? (
                    <RegisterSchools school={editingSchool} />
                ) : managingSchool ? (
                    <ManageSchool school={managingSchool} onClose={closeDrawer} />
                ) : (
                    <RegisterSchools />
                )}
            </Drawer>
        </div>
    );
};

export default School;
