import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;
// Function to create a new submission
export const createSubmission = async (schoolId, submissionData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/submissions/${schoolId}`, submissionData);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error creating submission');
    }
};

// Function to get submission by ID
export const getSubmissionById = async (submissionId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/submissions/${submissionId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error retrieving submission');
    }
};

// Function to get submissions by school
export const getSubmissionsBySchool = async (schoolId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/submissions/school/${schoolId}`);
        return response.data.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error retrieving submissions by school');
    }
};

// Function to get submissions by user
export const getSubmissionsByUser = async (userId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/submissions/user/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error retrieving submissions by user');
    }
};

// Function to get submissions by status
export const getSubmissionsByStatus = async (status) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/submissions/status/${status}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error retrieving submissions by status');
    }
};

// Function to update project verification status
export const updateProjectVerificationStatus = async (submissionId, verificationStatus, submissionType) => {
    try {
        const response = await axios.put(`${apiBaseUrl}/submissions/verify/${submissionId}/${submissionType}`, { verificationStatus });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Error updating project verification status');
    }
};
