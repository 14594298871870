import axios from 'axios';
import configs from '../../lib/config';

const apiBaseUrl = configs.apiBaseUrl;

export const getAnalyticsData = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/analytics`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};
