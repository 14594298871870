import React from 'react';
import { Layout } from 'antd';


const { Header } = Layout;

const Topbar = () => {
  return (
  <Header className="header" style={{ background: '#fff' }}>
  <div className="logo">
    <img src="/assets/logo.png" alt="Logo" style={{ height: '50px' }} />
  </div>
  </Header>
  );
};

export default Topbar;
