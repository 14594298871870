import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Create a new project
export const createProject = async (projectData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/projects`, projectData);
    return response.data;
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
};

// Get all projects
export const getProjects = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/projects`);
    return response.data.data;
  } catch (error) {
    console.error('Error getting projects:', error);
    throw error;
  }
};

// Get project by ID
export const getProjectById = async (projectId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting project by ID:', error);
    throw error;
  }
};

// Update project by ID
export const updateProject = async (projectId, projectData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/projects/${projectId}`, projectData);
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

// Delete project by ID
export const deleteProject = async (projectId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};
