// src/context/auth/adminLogin.js
import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Function to validate token
// const validateToken = async (token) => {
//   try {
//     const response = await axios.post(`${apiBaseUrl}/auth/validate-token`, { token });
//     return response.data.isValid;
//   } catch (error) {
//     return false;
//   }
// };

// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const isValid = true
      if (isValid) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        localStorage.removeItem('token');
        throw new Error('Invalid token');
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Login admin
export const loginAdmin = async (loginData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/admin/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};

// Login user
export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/user/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in user:', error);
    throw error;
  }
};
