// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/portal/login" replace />;
  }

  // Optionally, you can add a token validation check here
  // For example, you can decode the token and check its expiration

  return children;
};

export default PrivateRoute;
