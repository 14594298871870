import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Drawer, Form, Input, Select, Space, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { createAdmin, deleteAdmin, getAdmins, updateAdmin } from '../context/dbTransactions/admin';

const { Option } = Select;

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const data = await getAdmins();
      const usersArray = Array.isArray(data) ? data : [];
      setUsers(usersArray);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddUser = async (values) => {
    try {
      if (editingUser) {
        const updatedValues = { ...values };
        if (!values.password) {
          delete updatedValues.password; // Remove password if not provided
        }
        await updateAdmin(editingUser._id, updatedValues);
      } else {
        await createAdmin(values);
      }
      await fetchAdmins();
      setDrawerOpen(false);
      form.resetFields();
      setEditingUser(null);
    } catch (error) {
      console.error('Error adding/updating user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteAdmin(userId);
      await fetchAdmins();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    form.resetFields();
    setEditingUser(null);
  };

  const editUser = (user) => {
    setEditingUser(user);
    form.setFieldsValue({ ...user, password: '' }); // Do not set the password field
    setDrawerOpen(true);
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => editUser(record)}>Edit</Button>
          <Popconfirm title="Are you sure to delete this user?" onConfirm={() => handleDeleteUser(record._id)}>
            <Button icon={<DeleteOutlined />} danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Card
        title="Admin"
        extra={<Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>Add User</Button>}
      >
        <Table columns={columns} dataSource={users} rowKey="_id" />
      </Card>
      <Drawer
        title={editingUser ? "Edit User" : "Add User"}
        width={360}
        onClose={closeDrawer}
        open={drawerOpen}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddUser}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please input the username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={editingUser ? [] : [{ required: true, message: 'Please input the password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please select the role!' }]}
          >
            <Select>
              <Option value="superadmin">Superadmin</Option>
              <Option value="admin">Admin</Option>
              <Option value="manager">Manager</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingUser ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default Admin;
