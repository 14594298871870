import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {App as AntdApp, ConfigProvider} from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ConfigProvider theme={{token: {colorPrimary: "#FFB516"}}}>
          <AntdApp>
              <Router>
                  <App/>
              </Router>
          </AntdApp>
      </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
