import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Button, Drawer, message, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined, QrcodeOutlined } from '@ant-design/icons';
import { QRCodeCanvas } from 'qrcode.react';
import AddProjects from '../components/project/AddProjects';
import ViewProject from '../components/project/ViewProject';
import { getProjects, deleteProject } from '../context/dbTransactions/projects';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [drawerContent, setDrawerContent] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrProject, setQrProject] = useState(null);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const data = await getProjects();
      setProjects(data);
    } catch (error) {
      message.error('Failed to fetch projects.');
    }
  };

  const showDrawer = (content, project = null) => {
    setCurrentProject(project);
    setDrawerContent(content);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setCurrentProject(null);
    setDrawerContent(null);
  };

  const handleEdit = (record) => {
    showDrawer('edit', record);
  };

  const handleDelete = async (record) => {
    try {
      await deleteProject(record._id);
      message.success('Project deleted successfully.');
      fetchProjects();
    } catch (error) {
      message.error('Failed to delete project.');
    }
  };

  const handleView = (record) => {
    showDrawer('view', record);
  };

  const handleExportCSV = () => {
    const headers = ['Code', 'Name', 'Duration', 'Phase', 'Type', 'Status', 'CreatedAt', 'UpdatedAt'];
    const rows = projects.map(project => [
      project.code,
      project.name,
      project.duration,
      project.phase,
      project.type,
      project.status,
      project.createdAt,
      project.updatedAt
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'projects.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowQrCode = (project) => {
    setQrProject(project);
    setQrModalVisible(true);
  };

  const handleCloseQrModal = () => {
    setQrModalVisible(false);
    setQrProject(null);
  };

  const handleDownloadQrCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = `${qrProject.name}-qr-code.png`;
    link.click();
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button icon={<EyeOutlined />} style={{ marginRight: 8 }} onClick={() => handleView(record)} />
          <Button icon={<EditOutlined />} style={{ marginRight: 8 }} onClick={() => handleEdit(record)} />
          <Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} onClick={() => handleDelete(record)} />
          <Button icon={<QrcodeOutlined />} style={{ marginRight: 8 }} onClick={() => handleShowQrCode(record)} />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Card
        title="Projects"
        extra={
          <div>
            <Button type="primary" onClick={() => showDrawer('edit')} style={{ marginRight: 8 }}>
              Add Project
            </Button>
            <Button type="default" icon={<DownloadOutlined />} onClick={handleExportCSV}>
              Export CSV
            </Button>
          </div>
        }
      >
        <Table columns={columns} dataSource={projects} rowKey="_id" />
      </Card>
      <Drawer
        title={currentProject ? (drawerContent === 'edit' ? "Edit Project" : "View Project") : "Add Project"}
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {drawerContent === 'edit' ? (
          <AddProjects project={currentProject} onClose={closeDrawer} />
        ) : (
          <ViewProject project={currentProject} />
        )}
      </Drawer>
      <Modal
          title="Project QR Code"
          visible={qrModalVisible}
          onCancel={handleCloseQrModal}
          footer={[
            <Button key="download" type="primary" style={{ margin: '0 auto', display: 'block' }} onClick={handleDownloadQrCode}>
              Download QR
            </Button>
          ]}
      >
        {qrProject && (
            <div ref={qrCodeRef} style={{ textAlign: 'center' }}>
              <QRCodeCanvas value={JSON.stringify(qrProject)} size={256}  style={{padding:'20px'}}/>
              <div style={{ marginTop: 20 }}>
                <p><strong>Code:</strong> {qrProject.code}</p>
                <p><strong>Name:</strong> {qrProject.name}</p>
              </div>
            </div>
        )}
      </Modal>
    </div>
  );
};

export default Projects;
