import React from 'react';
import { Card } from 'antd';

const LandingPage = () => {
  return (
    <Card title="Welcome to the Landing Page" style={{ maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
      <p>This is the landing page of the application.</p>
    </Card>
  );
};

export default LandingPage;
