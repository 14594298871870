import React, { useEffect, useState } from 'react';
import { Tabs, Select, Spin, message, Card, Button, Drawer, Row, Col } from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getSchools } from '../context/dbTransactions/school';
import { getSubmissionsBySchool, updateProjectVerificationStatus } from '../context/dbTransactions/submission';

const { TabPane } = Tabs;
const { Option } = Select;

const Submissions = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Project');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const schoolsData = await getSchools();
        setSchools(schoolsData);
      } catch (error) {
        message.error('Failed to fetch schools');
      } finally {
        setLoading(false);
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const storedSchool = localStorage.getItem('selectedSchool');
    if (storedSchool) {
      setSelectedSchool(storedSchool);
    }
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

 useEffect(() => {

}, [selectedSchool, activeTab]);

  const fetchSubmissions = async () => {
    setLoading(true);
    try {
      const submissionsData = await getSubmissionsBySchool(selectedSchool);
      setSubmissions(submissionsData.filter(submission => submission.submissionType === activeTab));
    } catch (error) {
      message.error('Failed to fetch submissions');
    } finally {
      setLoading(false);
    }
  };

  const handleSchoolChange = (value) => {
    setSelectedSchool(value);
    localStorage.setItem('selectedSchool', value);
  };

  const handleTabChange = (key) => {
    localStorage.setItem('activeTab', key);
  };

  const handleUpdateStatus = async (submissionId, status,submissionType) => {
    try {
      await updateProjectVerificationStatus(submissionId, status, submissionType);
      fetchSubmissions();
      message.success(`Submission ${status}`);
    } catch (error) {
      message.error(`Failed to update submission status to ${status}`);
    }
  };

  const handleView = (submission) => {
    setSelectedSubmission(submission);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedSubmission(null);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Select
        showSearch
        placeholder="Select a school"
        style={{ width: 200, marginBottom: 16 }}
        onChange={handleSchoolChange}
        value={selectedSchool}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {schools.map(school => (
          <Option key={school._id} value={school._id}>
            {school.school_name}
          </Option>
        ))}
      </Select>
      {selectedSchool && (
        <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Projects" key="Project">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              {submissions.length > 0 ? (
                submissions.map(submission => (
                  <Card
                    key={submission._id}
                    title={`${submission.projectDetails?.code || ''} - ${submission.projectTitle || submission.visitTitle}`}
                    extra={<Button icon={<EyeOutlined />} onClick={() => handleView(submission)}>View</Button>}
                    style={{ width: 300 }}
                    actions={[
                      <Button type="primary" icon={<CheckOutlined />} onClick={() => handleUpdateStatus(submission._id, 'approved', 'Project')}>Accept</Button>,
                      <Button type="danger" icon={<CloseOutlined />} onClick={() => handleUpdateStatus(submission._id, 'rejected', 'Project')}>Reject</Button>
                    ]}
                  >
                    <p>Type: {submission.submissionType}</p>
                    <p>Status: {submission.verificationStatus}</p>
                  </Card>
                ))
              ) : (
                <p>No submissions available for the selected school.</p>
              )}
            </div>
          </TabPane>
          <TabPane tab="Van" key="Van">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              {submissions.length > 0 ? (
                submissions.map(submission => (
                  <Card
                    key={submission._id}
                    title={`${submission.projectDetails?.code || ''} - ${submission.projectTitle || submission.visitTitle}`}
                    extra={<Button icon={<EyeOutlined />} onClick={() => handleView(submission)}>View</Button>}
                    style={{ width: 300 }}
                    actions={[
                      <Button type="primary" icon={<CheckOutlined />} onClick={() => handleUpdateStatus(submission._id, 'approved', 'Van')}>Accept</Button>,
                      <Button type="danger" icon={<CloseOutlined />} onClick={() => handleUpdateStatus(submission._id, 'rejected', 'Van')}>Reject</Button>
                    ]}
                  >
                    <p>Type: {submission.submissionType}</p>
                    <p>Status: {submission.verificationStatus}</p>
                  </Card>
                ))
              ) : (
                <p>No submissions available for the selected school.</p>
              )}
            </div>
          </TabPane>
          <TabPane tab="Rejected" key="rejected">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              {submissions.filter(submission => submission.verificationStatus === 'rejected').length > 0 ? (
                submissions.filter(submission => submission.verificationStatus === 'rejected').map(submission => (
                  <Card
                    key={submission._id}
                    title={`${submission.projectDetails?.code || ''} - ${submission.projectTitle || submission.visitTitle}`}
                    extra={<Button icon={<EyeOutlined />} onClick={() => handleView(submission)}>View</Button>}
                    style={{ width: 300 }}
                  >
                    <p>Type: {submission.submissionType}</p>
                    <p>Status: {submission.verificationStatus}</p>
                  </Card>
                ))
              ) : (
                <p>No rejected submissions available for the selected school.</p>
              )}
            </div>
          </TabPane>
        </Tabs>
      )}
      <Drawer
        title="Submission Details"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={400}
      >
        {selectedSubmission && (
          <div>
            <Row gutter={[16, 16]}>
              <Col span={12}><strong>Project Code:</strong></Col>
              <Col span={12}>{selectedSubmission.projectDetails?.code}</Col>
              <Col span={12}><strong>Title:</strong></Col>
              <Col span={12}>{selectedSubmission.projectTitle || selectedSubmission.visitTitle}</Col>
              <Col span={12}><strong>Type:</strong></Col>
              <Col span={12}>{selectedSubmission.submissionType}</Col>
              <Col span={12}><strong>Status:</strong></Col>
              <Col span={12}>{selectedSubmission.verificationStatus}</Col>
              {/* Add more details as needed */}
            </Row>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Submissions;
