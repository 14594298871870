import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Create a new admin
export const createAdmin = async (adminData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/admin`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error creating admin:', error);
    throw error;
  }
};

// Get all admins
export const getAdmins = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/admin`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching admins:', error);
    throw error;
  }
};

// Get admin by ID
export const getAdminById = async (adminId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin by ID:', error);
    throw error;
  }
};

// Update admin by ID
export const updateAdmin = async (adminId, adminData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/admin/${adminId}`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error updating admin:', error);
    throw error;
  }
};

// Delete admin by ID
export const deleteAdmin = async (adminId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting admin:', error);
    throw error;
  }
};

// Login admin
export const loginAdmin = async (loginData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/admin/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};
