import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Drawer, message } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { deleteUser, getUsers } from "../context/dbTransactions/users";
import { getSchools } from "../context/dbTransactions/school";
import ManageAppUsersForm from "../components/users/MangeAppUsersForm";

const ManageAppUsers = () => {
  const [users, setUsers] = useState([]);
  const [schools, setSchools] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [drawerContent, setDrawerContent] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchSchools();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers();
      setUsers(data);
    } catch (error) {
      message.error('Failed to fetch users.');
    }
  };

  const fetchSchools = async () => {
    try {
      const data = await getSchools();
      setSchools(data);
    } catch (error) {
      message.error('Failed to fetch schools.');
    }
  };

  const showDrawer = (content, user = null) => {
    setCurrentUser(user);
    setDrawerContent(content);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setCurrentUser(null);
    setDrawerContent(null);
  };

  const handleEdit = (record) => {
    showDrawer('edit', record);
  };

  const handleDelete = async (record) => {
    try {
      await deleteUser(record._id);
      message.success('User deleted successfully.');
      fetchUsers();
    } catch (error) {
      message.error('Failed to delete user.');
    }
  };

  const handleView = (record) => {
    showDrawer('view', record);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'School Association',
      dataIndex: 'schoolAssociationRef',
      key: 'schoolAssociationRef',
      render: (school) => school?.school_name || 'N/A',
    },
    {
      title: 'Created At',
      dataIndex: 'timestamps',
      key: 'timestamps',
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
          <span>
          <Button icon={<EyeOutlined />} style={{ marginRight: 8 }} onClick={() => handleView(record)} />
          <Button icon={<EditOutlined />} style={{ marginRight: 8 }} onClick={() => handleEdit(record)} />
          <Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} onClick={() => handleDelete(record)} />
        </span>
      ),
    },
  ];

  return (
      <div>
        <Card
            title="App Users"
            extra={<Button type="primary" onClick={() => showDrawer('add')}>Add App User</Button>}
        >
          <Table  columns={columns} dataSource={users} rowKey="_id" />
        </Card>
        <Drawer
            title={currentUser ? (drawerContent === 'edit' ? "Edit User" : "User Activity") : "Add User"}
            width={720}
            onClose={closeDrawer}
            visible={drawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
        >
          {drawerContent === 'add' || drawerContent === 'edit' ? (
              <ManageAppUsersForm
                  user={currentUser}
                  schools={schools}
                  onClose={closeDrawer}
                  fetchUsers={fetchUsers}
              />
          ) : (
              <div>



              </div>
          )}
        </Drawer>
      </div>
  );
};

export default ManageAppUsers;
