import React, { useEffect, useState } from 'react';
import { Card, Spin, message, Button, Drawer, Tabs, Row, Col, Table } from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getSchools, updateVerificationStatus } from '../../context/dbTransactions/school';

const { TabPane } = Tabs;

const SchoolVerification = () => {
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    const fetchPendingSchools = async () => {
      try {
        const allSchools = await getSchools();
        setSchools(allSchools);
      } catch (error) {
        message.error('Failed to fetch schools');
      } finally {
        setLoading(false);
      }
    };

    fetchPendingSchools();
  }, []);

  const handleView = (school) => {
    setSelectedSchool(school);
    setDrawerVisible(true);
  };

  const handleApprove = async (schoolId) => {
    try {
      await updateVerificationStatus(schoolId, 'approved');
      message.success('School approved');
      setSchools(schools.filter(school => school._id !== schoolId));
    } catch (error) {
      message.error('Failed to approve school');
    }
  };

  const handleReject = async (schoolId) => {
    try {
      await updateVerificationStatus(schoolId, 'rejected');
      message.success('School rejected');
      setSchools(schools.filter(school => school._id !== schoolId));
    } catch (error) {
      message.error('Failed to reject school');
    }
  };

  if (loading) {
    return <Spin />;
  }

  const renderSchoolCard = (school) => (
    <Col xs={24} sm={12} md={8} lg={6} key={school._id}>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {school.school_name}
            <Button icon={<EyeOutlined />} onClick={() => handleView(school)}></Button>
          </div>
        }
        style={{ marginBottom: 16 }}
        cover={
          <img
            alt="School Logo"
            src={school.logo_url || 'https://roboticsonwheels.in/assets/logo.png'}
            style={{ height: 150, objectFit: 'contain' }}
          />
        }
        actions={[
          <Button icon={<CheckOutlined />} type="primary" onClick={() => handleApprove(school._id)} style={{ width: 'fit-content' }}>Approve</Button>,
          <Button icon={<CloseOutlined />} danger onClick={() => handleReject(school._id)} style={{ width: 'fit-content' }}>Reject</Button>
        ]}
      >
        <p>UDISE: {school.school_udias}</p>
        <p>Year of Establishment: {school.year_establishment}</p>
        <p>District: {school.school_district}</p>
        <p>State: {school.school_state}</p>
      </Card>
    </Col>
  );

  const columns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    { title: 'Value', dataIndex: 'value', key: 'value' }
  ];

  const dataSource = selectedSchool ? Object.keys(selectedSchool).map(key => ({
    key,
    field: key,
    value: selectedSchool[key]
  })) : [];

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pending" key="1">
          <Row gutter={[16, 16]}>
            {schools.filter(school => school.verification_status === 'pending').map(renderSchoolCard)}
          </Row>
        </TabPane>
        <TabPane tab="Rejected" key="2">
          <Row gutter={[16, 16]}>
            {schools.filter(school => school.verification_status === 'rejected').map(renderSchoolCard)}
          </Row>
        </TabPane>
      </Tabs>

      <Drawer
        title={selectedSchool?.school_name}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={600}
      >
        {selectedSchool && (
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
          />
        )}
      </Drawer>
    </div>
  );
};

export default SchoolVerification;
