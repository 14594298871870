import React from 'react';
import Login from "../components/auth/Login";

function Auth() {
  return (
    <div className="App">
      <Login />
    </div>
  );
}

export default Auth;
