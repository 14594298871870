import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: 'center' }}>
        ROW ©{new Date().getFullYear()} Created by ROW Team
    </Footer>
  );
};

export default AppFooter;
