import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Create a new school
export const createSchool = async (schoolData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/school`, schoolData);
    return response.data;
  } catch (error) {
    console.error('Error creating school:', error);
    throw error;
  }
};

// Get all schools
export const getSchools = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/school`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching schools:', error);
    throw error;
  }
};

// Get school by ID
export const getSchoolById = async (schoolId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/school/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching school by ID:', error);
    throw error;
  }
};

// Update school by ID
export const updateSchool = async (schoolId, schoolData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/school/${schoolId}`, schoolData);
    return response.data;
  } catch (error) {
    console.error('Error updating school:', error);
    throw error;
  }
};

// Delete school by ID
export const deleteSchool = async (schoolId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/school/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting school:', error);
    throw error;
  }
};

// Update verification status of a school by ID
export const updateVerificationStatus = async (schoolId, verification_status) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/school/${schoolId}/verification-status`, { verification_status });
    return response.data;
  } catch (error) {
    console.error('Error updating verification status:', error);
    throw error;
  }
};
